import React from 'react';

//components
import ContactForm from '../components/contact-form';

//media
import backgroundImage from '../assets/kubasi_background_image_2.webp'

function SectionContact() {
	return (
		<section id='contact'>
			<div
				className="contact-container"
				style={{
					backgroundImage: `url(${backgroundImage})`,
					backgroundPosition: 'center',
					backgroundSize: 'cover',
					backgroundRepeat: 'no-repeat',
					width: '100vw',
					height: '100vh'
				}}
			>
				<div className='contact-container-inner'>
					<div className="contact-text-wrapper">
						<h1 className="contact-h1">Say Hello</h1>
						<p>Fill out the form to say Hi!</p>
					</div>

					<ContactForm />

					{/* <div className='contact-social-media-text'>
						<p>Or find me on Social Media: </p>
					</div> */}
					
					{/*TODO: This is lazy */}
					<br />

					<div className="contact-social-media-links">
						<a href="https://www.linkedin.com/in/louis-goodwin-2a0281166/" target="_blank" rel="noopener noreferrer" className='contact-social-link' aria-label='Toggle Audio Play'>
							<i className="fab fa-linkedin"></i>
						</a>
						<a href="https://www.instagram.com/louis_goodwin/" target="_blank" rel="noopener noreferrer" className='contact-social-link' aria-label='Toggle Audio Play'>
							<i className="fab fa-instagram"></i>
						</a>
						<a href="https://open.spotify.com/artist/1bwzj3yyd0wXUSFFLKYnIZ?si=K7SwVt2PSVqUrOPiD_49xw" target="_blank" rel="noopener noreferrer" className='contact-social-link' aria-label='Toggle Audio Play'>
							<i className="fab fa-spotify"></i>
						</a>
					</div>
				</div>
			</div>
		</section>
	);
}

export default SectionContact;