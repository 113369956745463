import React from 'react';
import { scrollToSection } from '../components/utils';

//media
import aboutImage from '../assets/louis-goodwin-image.webp'

function SectionAbout() {
	return (
		<section className="about-container" id='about'>
			<div className='about-content'>
				<div className='about-content-top'>
					<div className='about-content-left'>
						<img className='about-image' loading='lazy' alt='Louis Goodwin - Sound Designer and Musician' src={aboutImage}></img>
					</div>

					<div className='about-content-right'>
						<div>
							<h1 className="about-title">About</h1>
							<p className='about-text'>
								Hi, I'm Kubasi, AKA Louis Goodwin, a dedicated Sound Designer and Musician based in the UK. With a passion for sonic creativity that spans music, film, and game design, I am fascinated by pushing boundaries in the sphere of audio design.
							</p >
							<p className='about-text'>
								I have entered the world of sound-design with a MA in Music Technology from the London College of Music. My thesis explores <a onClick={() => scrollToSection('aNST')} className='about-link'>Audio Neural Style Transfer</a>, a method of harnessing convolutional neural networks to reshape audio samples, expanding the horizons of sound design and abstract electronic music.
							</p>
							<p className='about-text'>
								Further projects at LCM include <a onClick={() => scrollToSection('research')} className='about-link'>GrainFlocks</a>, - an award-winning interactive sonic art installation - and explorations of spatial audio techniques, including Dolby Atmos and Ambisonics, that I wish to continue to explore in game and on screen.
							</p>
							<p className='about-text'>
								As <a href='https://open.spotify.com/artist/1bwzj3yyd0wXUSFFLKYnIZ?si=K7SwVt2PSVqUrOPiD_49xw' target="_blank" rel="noopener noreferrer" className='about-link'>Kubasi</a>, I produce genre-blending electronic <a onClick={() => scrollToSection('music')} className='about-link'>music</a> that showcases the same textures and technicalities I bring to my sound design.
							</p>
						</div>

						<div className='about-contact-button-container'>
							<button onClick={() => scrollToSection('contact')} className='about-contact-button'><p>Want to Know more?</p></button>
						</div>
					</div>
				</div>
			</div>
		</section>
	);
}

export default SectionAbout;