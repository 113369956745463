import React, { memo, useState, useCallback } from 'react';
import YouTube from 'react-youtube';

const PortfolioContentItem = ({ PortfolioItem }) => {
	const [isVideoVisible, setIsVideoVisible] = useState(false);
	const [isUserPaused, setIsUserPaused] = useState(false);

    const playVideo = useCallback(() => {
        setIsVideoVisible(true);
        setIsUserPaused(false);
    }, []);

    const handlePause = useCallback(() => {
        setIsUserPaused(true);
    }, []);

	const handleStateChange = useCallback((event) => {
		if (event.data === YouTube.PlayerState.PLAYING) {
			setIsVideoVisible(true);
			setIsUserPaused(false);
		} else if (event.data === YouTube.PlayerState.PAUSED) {
			setIsUserPaused(true);
			setTimeout(() => {
				if (isUserPaused) {
					setIsVideoVisible(false);
				}
			}, 1000);
		}
	}, [isUserPaused]);

	return (
		<div className="portfolio-content-item" onClick={playVideo}>
			{isVideoVisible ? (
				<YouTube
					videoId={PortfolioItem.videoID}
					className={`portfolio-content-item-youtube-player ${isVideoVisible
							? "portfolio-content-item-fade-in"
							: "portfolio-content-item-fade-out"
						}`}
					opts={{
						width: "320",
						height: "180",
						playerVars: {
							playsinline: 1,
							autoplay: 1,
							modestbranding: 1
						},
					}}
					onStateChange={handleStateChange}
					onPause={handlePause}
				/>
			) : (
				<div className="portfolio-content-item-container" role="button" tabIndex="0">
					<img
						className="portfolio-content-item-img"
						loading='lazy'
						srcSet={require(`../assets/portfolio-thumbnails/${PortfolioItem.thumbnailImg}`)}
						alt={PortfolioItem.thumbnailAlt}
					/>
					<div className="portfolio-content-item-overlay">
						<h1 className="portfolio-content-item-title">
							{PortfolioItem.title}
						</h1>
						<p className="portfolio-content-item-text">
							{PortfolioItem.description}
						</p>
					</div>
				</div>
			)}
		</div>
	);
};

export default memo(PortfolioContentItem);