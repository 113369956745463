import React, { useRef, useEffect } from "react";
import p5 from "p5";

const sketch = p => {
	const flock = [];
	let numBoids = 80;

	if (p.windowWidth < 768) {
		numBoids = 30;
	}

	class Boid {
		constructor(x = p.random(p.windowWidth), y = p.random(window.innerHeight)) {
			this.maxForce = 0.2;
			this.maxSpeed = 8;
			this.position = p.createVector(x, y);
			this.velocity = p5.Vector.random2D();
			this.velocity.setMag(this.maxSpeed);
			this.acceleration = p.createVector();
			this.color = p.color(255);
		};

		edges() {
			if (this.position.x > p.windowWidth) {
				this.position.x = 0;
			} else if (this.position.x < 0) {
				this.position.x = p.windowWidth;
			} else if (this.position.y > window.innerHeight) {
				this.position.y = 0;
			} else if (this.position.y < 0) {
				this.position.y = window.innerHeight;
			}
		};

		update() {
			this.position.add(this.velocity);
			this.velocity.add(this.acceleration);
			this.velocity.limit(this.maxSpeed);
			this.acceleration.mult(0);
		}

		getNearbyBoids(boids, radius) {
			let nearby = []
			for (let i = 0; i < boids.length; i++) {
				let other = boids[i];
				let d = p.dist(this.position.x, this.position.y, other.position.x, other.position.y);
				if (d < radius && other != this) {
					nearby.push(other);
				}
			}

			return nearby;
		};

		calculateSteering(steering, boids, calculationFunction) {
			boids.forEach(calculationFunction);
			if (boids.length > 0) {
				steering.div(boids.length);
				steering.setMag(this.maxSpeed);
				steering.sub(this.velocity);
				steering.limit(this.maxForce);
			}
			return steering;
		};
		
		align(boids) {
			let steering = p.createVector();
			return this.calculateSteering(steering, boids, other => {
				steering.add(other.velocity);
			});
		}
		
		cohesion(boids) {
			let steering = p.createVector();
			return this.calculateSteering(steering, boids, other => {
				steering.add(other.position);
			});
		}
		
		separation(boids) {
			let steering = p.createVector();
			return this.calculateSteering(steering, boids, other => {
				let diff = p5.Vector.sub(this.position, other.position);
				diff.div(diff.mag());
				steering.add(diff);
			});
		}
		
		externalForce() {
			let perceptionRadius = 200;
			let steering = p.createVector();
			let mousePosition = p.createVector(p.mouseX, p.mouseY);

			let d = p.dist(this.position.x, this.position.y, p.mouseX, p.mouseY);
			if (d < perceptionRadius) {
				let diff = p5.Vector.sub(mousePosition, this.position);
				steering.add(diff);
				steering.setMag(this.maxSpeed * 2);
				steering.limit(this.maxForce * 2);
			}

			return steering;
		};

		flock(boids) {
			let within_50 = this.getNearbyBoids(boids, 50);

			let alignment = this.align(within_50);
			let cohesion = this.cohesion(within_50);
			let separation = this.separation(within_50);

			alignment.mult(1);
			cohesion.mult(1);
			separation.mult(2);


			this.acceleration.add(alignment);
			this.acceleration.add(cohesion);
			this.acceleration.add(separation);
			this.acceleration.add(this.externalForce());
		}

		show() {
			this.edges();
			p.noFill();
			p.stroke(this.color);
			p.circle(this.position.x, this.position.y, 10);
		}
	};

	p.setup = () => {
		p.createCanvas(window.innerWidth, window.innerHeight);
		initialiseBoids();
	};

	const initialiseBoids = () => {
		while (flock.length > 0) {
			flock.pop();
		}

		for (let i = 0; i < numBoids; i++) {
			flock.push(new Boid());
		}
	}

	p.windowResized = () => {
		p.resizeCanvas(p.windowWidth, p.windowHeight);
	};

	p.draw = () => {
		p.background(0, 0, 0, 120);

		const colorValue = (p.sin(p.frameCount * 0.02) + 1) * 128;
		const boidColor = p.color(colorValue, 100, 200);

		for (let boid of flock) {
			boid.flock(flock);
			boid.update();

			boid.color = boidColor;

			boid.show();
		}
	};

	return () => {
		p.remove();
	};
};

export default function BoidBackground() {
	const boidBackground = useRef();

	useEffect(() => {
		let newp5 = new p5(sketch, boidBackground.current);

		return () => {
			newp5.remove();
		};
	}, []);

	return <div className="BoidBackground" ref={boidBackground} />;
}

