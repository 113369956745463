import React from 'react';

function ContactForm() {

    return (
        <div className="contact-form-padding">
            <div className='contact-form-container'>
            <form name="contact" method="POST" data-netlify="true" action="/pages/success">
                    <div className="contact-form-name-inputs">
                        <div className="contact-form-input-box contact-form-input-name-left">
                            <input
                                className='contact-form-input'
                                placeholder='First Name  (Required)'
                                type="text"
                                id="firstName"
                                name="firstName"
                                required
                                autoComplete="given-name" 
                            />
                        </div>
                        <div className="contact-form-input-box contact-form-input-name">
                            <input
                                className='contact-form-input'
                                placeholder='Last Name  (Required)'
                                type="text"
                                id="lastName"
                                name="lastName"
                                required
                                autoComplete="family-name" 
                            />
                        </div>
                    </div>

                    <div className="contact-form-input-box">
                        <input
                            className='contact-form-input'
                            placeholder='Email  (Required)'
                            type="email"
                            id="email"
                            name="email"
                            required
                            autoComplete="email" 
                        />
                    </div>

                    <div className="contact-form-input-box">
                        <input
                            className='contact-form-input'
                            placeholder='Subject'
                            type="text"
                            id="subject"
                            name="subject"
                            required
                            autoComplete="organization" 
                        />
                    </div>

                    <div className="contact-form-input-box">
                        <textarea
                            className='contact-form-input contact-form-input-message'
                            placeholder='Message  (Required)'
                            type="text"
                            id="message"
                            name="message"
                            required
                            autoComplete="off" 
                        />
                    </div>
                    <div className='contact-form-submit-button-container'>
                        <button type="submit" className='contact-form-submit-button'>Submit</button>
                    </div>  
                </form>
            </div>
        </div>
    );
}

export default ContactForm;
