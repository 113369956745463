import React from 'react';

//components
import AudioPlayer from '../components/audio-player';

//data
import MusicContent from '../data/music-content';

//media
import backgroundImage from '../assets/kubasi_background_image_1.webp'


function SectionMusic() {
	return (
		<section id='music'>
			
			<div
				className="music-container"
				style={{
					backgroundImage: `url(${backgroundImage})`,
					backgroundPosition: 'center',
					backgroundSize: 'cover',
					backgroundRepeat: 'no-repeat',
					width: '100vw',
					height: '100vh'
				}}
			>
				<div className='music-container-inner'>
					<div className="music-text-wrapper">
						<h1 className="music-h1">Music</h1>
						{/* <p className='music-text'>An audio showcase of origional compositions, production collaborations, and film score.</p> */}
					</div>
					
					<AudioPlayer AudioContent={MusicContent} />
					
				</div>

			</div>
		</section>
	);
}

export default SectionMusic;