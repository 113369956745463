const PortfolioContent = [
    {
        id: 1,
        title: "Kubasi - Sapiens Visualiser",
        videoID: "0RZXdH1wzl4",
        thumbnailImg: "kubasi-sapiens-thumbnail.webp",
        thumbnailAlt: "Kubasi - Sapiens Visualiser",
        description: "An original composition and music visualiser, created partially with AI video generation."
    },

    {
        id: 2,
        title: "MSubs - Pitch Video 2023",
        videoID: "s4RLdSx0iIg",
        thumbnailImg: "msubs-promo-thumbnail.webp",
        thumbnailAlt: "MSubs Promotional Video 2023",  
        description: "A short promotional video for submersible manufacturer MSubs. Features VO, and origional sound design and music.",     
    },

    {
        id: 3,
        title: "The Kites - Many Moons of Jupiter",
        videoID: "mwIROChKZgc",
        thumbnailImg: "the-kites-many-moons-thumbnail.webp",
        thumbnailAlt: "The Kites - The Many Moons Of Jupiter (Official Music Video)",
        description: "Intro Music and Sound Design, plus Music Mixing"
    },

    { 
        id: 4,
        title: "Vilk Collective - Circle",
        videoID: "3GN56PLiV8s",
        thumbnailImg: "circle-thumbnail.jpg",
        thumbnailAlt: "Vilk Collective 'Circle' - 360 Experience",  
        description: "An Ambisonic mix taken from the live performance of Vilk Collective's 'Circle' Audiovisual Experience.", 
    },

    {
        id: 5,
        title: "Marine AI - Pitch Video 2023",
        videoID: "qzt3izAcdTk",
        thumbnailImg: "mai-promo-thumbnail.webp",
        thumbnailAlt: "Marine AI Promotional Video 2023",
        description: "A short promotional video for autonomous marine software developers Marine AI - Origonal Score + Sound Design."
    },

    {
        id: 6,
        title: "Tom & Ellie's Wedding",
        videoID: "lsPM0UIuVIk",
        thumbnailImg: "tom-ellie-wedding-thumbnail.webp",
        thumbnailAlt: "Tom and Ellie Rowson - Wedding Film",
        description: "Bespoke Wedding Composition + Sound Restoration."
    },

    {
        id: 7,
        title: "The Hundred Hands - 2021 Showreel",
        videoID: "C0_qCo4TFZ4",
        thumbnailImg: "the-hundred-hands-2021-portfolio-thumbnail.webp",
        thumbnailAlt: "The Hundred Hands - 2021 Showreel",
        description: 'Music and Sound Design, Website and further content available at thehundredhands.co.uk'
    },

    // {
    //     id: 7,
    //     title: "State of the Earth",
    //     videoID: "epLH8f-aIJc",
    //     thumbnailImg: "soil-documentary-thumbnail.webp",
    //     thumbnailAlt: "Sustainable Farming Documentary - Outro Score",
    //     description: "Origional score snippet - Currently in Re-edit"
    // },

    {
        id: 8,
        title: "Familiar - LFA Short Film",
        videoID: "CKsmNGn7-zE",
        thumbnailImg: "short-film-thumbnail.webp",
        thumbnailAlt: "Familiar - London Film Academy Short",
        description: "Origional Short Film score."
    },

    {
        id: 9,
        title: "Louise Parker - One Note Samba",
        videoID: "Qp6dS-tLvns",
        thumbnailImg: "louise-parker-live-thumbnail.webp",
        thumbnailAlt: "Louise Parker - &quot;One Note Samba&quot;  - with Jem Fatna, Martin Jenkins and Ron Phelan",
        description: "Audio Restoration - Recovering professional quality audio from a single low-fidelity recording."
    },
];
  
export default PortfolioContent;