import React, { useState, useEffect, useCallback } from 'react';
import { scrollToSection } from "../components/utils";

//media
import showreelPoster from '../assets/kubasi-sapiens-trimmed-poster.webp';

function SectionShowreel() {
	const [showreelVideo, setVideoSource] = useState();

	useEffect(() => {
		import('../assets/kubasi-sapiens-trimmed.mp4')
			.then(src => setVideoSource(src.default))
			.catch(err => console.log(err));
	}, []);

	const handleViewPortfolio = useCallback(() => {
		scrollToSection('portfolio');
	}, []);

	return (
		<section id='showreel'>
			<div className='showreel-container'>
				<video
					className='showreel-video'
					src={showreelVideo}
					poster={showreelPoster}
					autoPlay
					muted
					loop
					loading="lazy"
					title="Kubasi Showreel Video">
				</video>

				<div className='showreel-play-button-hover-area'>
					<button
						className="showreel-play-button"
						onClick={handleViewPortfolio}
						role="button"
						tabIndex="0">
							View Portfolio
						<div className='showreel-play-button-triangle' />
					</button>
				</div>
			</div>
		</section>
	);
}

export default SectionShowreel;