import React from "react";

//components
import PortfolioContentItem from "../components/portfolio-content-item";

//data
import PortfolioContent from "../data/portfolio-content";

function SectionPortfolio() {

	return (
		<section id="portfolio">
			<div className="portfolio-container">
				<div className="portfolio-container-inner">
					<div className="portfolio-text-wrapper">
						<h1 className="portfolio-h1">Selected Works</h1>
						<p className="portfolio-text">
							A short selection of score, sound design, and audio restoration for a number of clients and projects.
						</p>
					</div>

					<div className="portfolio-content-grid">
						{PortfolioContent.map(PortfolioItem => (
							<PortfolioContentItem key={PortfolioItem.id} PortfolioItem={PortfolioItem} />
						))}
					</div>
				</div>
			</div>
		</section>
	);
}

export default SectionPortfolio;    