import React, { useState } from 'react';
import { scrollToSection } from "./utils";

function Header() {
	const [modalOpen, setModalOpen] = useState(false);

	//lock scroll when modal is open
	const openModal = () => {
		setModalOpen(!modalOpen);
		if (!modalOpen) {
			document.body.classList.add('no-scroll');
		} else {
			document.body.classList.remove('no-scroll');
		}
	}

	const handleMobileNavClick = (sectionId) => {
		scrollToSection(sectionId);
		openModal();
	};

	return (
		//Landing page header
		<header role="banner" className='header-main'>
			<div className="header-inner">
				{/*logo*/}
				<div className="header-logo-wrapper">
					<h1 className="header-kubasi-logo">KUBASI</h1>
				</div>

				{/*nav*/}
				<div className="header-nav-wrapper">
					<a onClick={() => scrollToSection('portfolio')}><p className="header-nav-text">Portfolio</p></a>
					<a onClick={() => scrollToSection('music')}><p className="header-nav-text">Music</p></a>
					{/* <a href="#sound"><p className="header-nav-text">Sound</p></a> */}
					<a onClick={() => scrollToSection('research')}><p className="header-nav-text">Research</p></a>
					<a onClick={() => scrollToSection('about')}><p className="header-nav-text">About</p></a>
					<a onClick={() => scrollToSection('contact')}><p className="header-nav-text">Contact</p></a>
					<a onClick={() => scrollToSection('game')}><p className="header-nav-text">Play a Game</p></a>
				</div>

				{/*mobile open modal button*/}
				{!modalOpen ? (
					<div className='header-nav-mobile-button-open' onClick={openModal}>
						<svg xmlns="http://www.w3.org/2000/svg" width="30" height="30" viewBox="0 0 30 30">
							<rect y="4" width="30" height="3" rx="0" fill="#eeeeee" />
							<rect y="14" width="30" height="3" rx="0" fill="#eeeeee" />
							<rect y="24" width="30" height="3" rx="0" fill="#eeeeee" />
						</svg>
					</div>
				) : (
					<div className='header-nav-mobile-button-close' onClick={openModal}>
						<svg
							xmlns="http://www.w3.org/2000/svg"
							width="30"
							height="30"
							viewBox="0 0 16 16"
						>
							<path d="M4.354 4.354a.5.5 0 0 0-.708-.708L8 7.293 4.354 3.646a.5.5 0 1 0-.708.708L7.293 8l-3.647 3.646a.5.5 0 1 0 .708.708L8 8.707l3.646 3.647a.5.5 0 0 0 .708-.708L8.707 8l3.647-3.646a.5.5 0 0 0-.708-.708L8 7.293 4.354 3.646z" fill="#eeeeee" />
						</svg>
					</div>
				)}
			</div>

			{/*mobile open modal*/}
			<div className={`header-nav-modal-hide ${modalOpen ? 'header-nav-modal' : ''}`}>
				<div className='header-nav-modal-wrapper'>
					<a onClick={() => handleMobileNavClick('portfolio')}><p className="header-nav-text">Portfolio</p></a>
					<a onClick={() => handleMobileNavClick('music')}><p className="header-nav-text">Music</p></a>
					{/* <a href="#sound" onClick={openModal}><p className="header-nav-text">Sound</p></a> */}
					<a onClick={() => handleMobileNavClick('research')}><p className="header-nav-text">Research</p></a>
					<a onClick={() => handleMobileNavClick('about')}><p className="header-nav-text">About</p></a>
					<a onClick={() => handleMobileNavClick('contact')}><p className="header-nav-text">Contact</p></a>
					{/* <a onClick={() => handleMobileNavClick('game')}><p className="header-nav-text">Play a Game</p></a> */}
				</div>
			</div>
		</header>
	);
}

export default Header;