import React, { useState } from "react";
import { scrollToSection } from "./utils";

const NavBar = () => {
    const [navOpen, setnNavOpen] = useState(false);

    const toggleNavOpen = () => {
        setnNavOpen(!navOpen);
        if (!navOpen) {
            document.body.classList.add('no-scroll');
        } else {
            document.body.classList.remove('no-scroll');
        }
    }

    const handleMobileNavClick = (sectionId) => {
		scrollToSection(sectionId);
		toggleNavOpen();
	};

    return (
        <div className="section-nav-bar-container">
            <div className="section-nav-bar-inner">
                <a onClick={() => scrollToSection('portfolio')}><p className="section-nav-bar-text"><PortfolioIcon />Portfolio</p></a>
                <a onClick={() => scrollToSection('music')}><p className="section-nav-bar-text"><MusicIcon /> Music</p></a>
                {/* <a href="#sound"><p className="section-nav-bar-text"><SoundIcon /> Sound</p></a> */}
                <a onClick={() => scrollToSection('research')}><p className="section-nav-bar-text"><ResearchIcon /> Research</p></a>
                <a onClick={() => scrollToSection('about')}><p className="section-nav-bar-text"><AboutIcon /> About</p></a>
                <a onClick={() => scrollToSection('contact')}><p className="section-nav-bar-text"><ContactIcon /> Contact</p></a>
                <a onClick={() => scrollToSection('game')}><p className="section-nav-bar-text"><GameIcon /> Play a Game</p></a>
            </div>

            <div className="section-nav-bar-mobile">
                <button className="section-nav-bar-mobile-button" onClick={toggleNavOpen}>
                    <img
                        src={process.env.PUBLIC_URL + '/kubasi-icon.svg'}
                        alt="Kubasi Icon"
                        className={`section-nav-bar-mobile-icon ${navOpen ? 'section-nav-bar-mobile-icon-rotate' : ''}`} />
                </button>
            </div>

            <div className={`section-nav-bar-menu-hide ${navOpen ? 'section-nav-bar-menu' : ''}`}>
                <div className='section-nav-bar-menu-wrapper'>
                    <a onClick={() => handleMobileNavClick('portfolio')}><p className="section-nav-bar-menu-text"><PortfolioIcon /> <span className="section-nav-bar-space"/> Portfolio</p></a>
                    <a onClick={() => handleMobileNavClick('music')}><p className="section-nav-bar-menu-text"><MusicIcon /> <span className="section-nav-bar-space"/> Music</p></a>
                    {/* <a href="#sound" onClick={toggleNavOpen}><p className="section-nav-bar-menu-text"><SoundIcon /> <span className="section-nav-bar-space"/> Sound</p></a> */}
                    <a onClick={() => handleMobileNavClick('research')}><p className="section-nav-bar-menu-text"><ResearchIcon /> <span className="section-nav-bar-space"/> Research</p></a>
                    <a onClick={() => handleMobileNavClick('about')}><p className="section-nav-bar-menu-text"><AboutIcon /> <span className="section-nav-bar-space"/> About</p></a>
                    <a onClick={() => handleMobileNavClick('contact')}><p className="section-nav-bar-menu-text"><ContactIcon /> <span className="section-nav-bar-space"/> Contact</p></a>
                    {/* <a onClick={() => handleMobileNavClick('game')}><p className="section-nav-bar-menu-text"><GameIcon /> <span className="section-nav-bar-space"/> Play a Game</p></a> */}
                </div>
            </div>
        </div>
    )
}

export default NavBar;

{/*Icon SVGs*/}
export const PortfolioIcon = () => {
    return (
        <svg className='section-nav-bar-icon' xmlns="http://www.w3.org/2000/svg" width="48" height="48" viewBox="0 0 48 48">
            <rect width="48" height="48" fill="none" />

            <rect x="10%" y="10%" width="80%" height="80%" rx="25%" ry="25%" fill="none" stroke="white" strokeWidth="7" />
        </svg>
    )
};

export const MusicIcon = () => {
    return (
        <svg className='section-nav-bar-icon' xmlns="http://www.w3.org/2000/svg" width="48" height="48" viewBox="0 0 48 48">
            <rect width="48" height="48" fill="none" />

            <ellipse cx="46.26%" cy="83%" rx="20%" ry="16%" fill="white" />
            <line x1="60%" y1="0%" x2="60%" y2="83%" stroke="white" strokeWidth="6" />
        </svg>
    )
}

export const SoundIcon = () => {
    return (
        <svg className='section-nav-bar-icon' xmlns="http://www.w3.org/2000/svg" width="48" height="48" viewBox="0 0 48 48">
            <rect width="48" height="48" fill="none" />

            <circle cx="50%" cy="50%" r="40%" fill="none" stroke="white" strokeWidth="7" />

            <circle cx="50%" cy="50%" r="16%" fill="white" />
        </svg>
    )
}

export const ResearchIcon = () => {
    return (
        <svg className='section-nav-bar-icon' xmlns="http://www.w3.org/2000/svg" width="48" height="48" viewBox="0 0 48 48">
            <rect width="48" height="48" fill="none" />

            <circle cx="50%" cy="50%" r="40%" fill="none" stroke="white" strokeWidth="7" />
        </svg>
    )
}

export const AboutIcon = () => {
    return (
        <svg className='section-nav-bar-icon' xmlns="http://www.w3.org/2000/svg" width="48" height="48" viewBox="0 0 48 48">
            <rect width="48" height="48" fill="none" />

            <circle cx="50%" cy="100%" r="40%" fill="none" stroke="white" strokeWidth="7" />
            <circle cx="50%" cy="25%" r="15%" fill="none" stroke="white" strokeWidth="7" />
        </svg>
    )
}

export const ContactIcon = () => {
    return (
        <svg className='section-nav-bar-icon' xmlns="http://www.w3.org/2000/svg" width="48" height="48" viewBox="0 0 48 48">
            <rect width="48" height="48" fill="none" />

            <rect x="10%" y="20%" width="80%" height="60%" rx="15%" ry="15%" fill="none" stroke="white" strokeWidth="7" />
            <line x1="40%" y1="50%" x2="60%" y2="50%" stroke="white" strokeLinecap="round" strokeWidth="7" />
        </svg>
    )
}

export const GameIcon = () => {
    return (
        <svg className='section-nav-bar-icon' xmlns="http://www.w3.org/2000/svg" width="48" height="48" viewBox="0 0 48 48">
            <rect width="48" height="48" fill="none" />

            <polygon points="24,10 42,42 6,42" fill="none" stroke="white" strokeWidth="6" />
        </svg>
    )
}