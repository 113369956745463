const MusicContent = [
    {
        id: 1,
        title: "Sapiens", 
        artist: "Kubasi",
        description: "An Ambient/Garage track inspired by the likes of Four Tet and Floating Points.",
        url: "sapiens.mp3",      
    },
    {
        id: 2,
        title: "Hallucinate", 
        artist: "Leo, Kubasi",
        description: "A Pop collaberation with singer/song writer Leo. Inspired by the works of Disclosure and Ian Kirkpatrick",
        url: "hallucinate.mp3",  
    },
    {
        id: 3,
        title: "Phenomena",
        artist: "Kubasi",
        description: "Textural Garage",
        url: "phenomena.mp3"
    },
    {
        id: 4,
        title: "Departure",
        artist: "Kubasi",
        description: "Ambient electronica",
        url: "departure.mp3"
    },
    {
        id: 5,
        title: "Spell", 
        artist: "Leo, Kubasi",
        description: "A Pop/techno collaberation with singer/song writer Leo.",
        url: "spell.mp3",  
    },
    {
        id: 6,
        title: "Spell (Kubasi Remix)", 
        artist: "Kubasi",
        description: "A garage take on my collaberative work with Leo.",
        url: "spell-remix.mp3",  
    },
    {
        id: 7,
        title: "Resolve", 
        artist: "Kubasi",
        description: "A combination of IDM, metal and prog. Think Polyphia meets Mr. Bill",
        url: "resolve.mp3",  
    },
    {
        id: 8,
        title: "Cafetiere", 
        artist: "Kubasi",
        description: "An exploration of noise and texture.",
        url: "cafetiere.mp3",  
    },
    {
        id: 9,
        title: "Sparsity", 
        artist: "Kubasi",
        description: "More combinations of metal and IDM, this time a deeper exploration of texture and foley",
        url: "sparsity.mp3",  
    },

    {
        id: 10,
        title: "Beneath", 
        artist: "Kubasi",
        description: "A Rob Clouth inspired cinematic piece exploring Concatenative Synthesis",
        url: "beneath.mp3",  
    },
];
  
export default MusicContent;