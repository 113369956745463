import React, { useState, useRef, useEffect, useCallback, memo } from 'react';
import classNames from 'classnames';

function AudioPlayer({ AudioContent }) {
    const audioRefs = useRef([]);

    // Initialize audioRefs when the component mounts or when AudioContent changes
    useEffect(() => {
        audioRefs.current = Array(AudioContent.length + 1).fill().map((_, i) => audioRefs.current[i] || React.createRef());
    }, [AudioContent]);

    //holds the track states for "playing/paused", "expanded/collapased", and time position
    const [trackStates, setTrackStates] = useState(
        AudioContent.reduce((acc, track) => {
            acc[track.id] = {
                playing: false,
                expanded: false,
                position: 0,
            };
            return acc;
        }, {})
    );

    const togglePlayPause = useCallback((track) => {
        // Get track id
        const trackId = track.id;
        // Create variable for updating track states
        const updatedTrackStates = { ...trackStates };

        // Set track states
        for (const id in updatedTrackStates) {
            if (id != trackId) {
                // Sets all other tracks to "pause"
                updatedTrackStates[id].playing = false;
                updatedTrackStates[id].position = 0;

                if (audioRefs.current[id] && audioRefs.current[id].current) {
                    const audioElement = audioRefs.current[id].current;
                    audioElement.pause();
                }

            } else {
                // Toggles clicked track play/pause
                const audioElementRef = audioRefs.current[trackId];

                if (audioElementRef && audioElementRef.current) {
                    // Access the HTML audio element
                    const audioElement = audioElementRef.current;

                    //toggle playing state
                    updatedTrackStates[trackId].playing = !updatedTrackStates[trackId].playing;

                    //play if playing, pause if pausing
                    if (updatedTrackStates[trackId].playing) {
                        //get audio source
                        if (audioElement.src !== `${process.env.PUBLIC_URL}/audio/music/${track.url}`) {
                            audioElement.src = `${process.env.PUBLIC_URL}/audio/music/${track.url}`;
                        }

                        //get stored position
                        const storedPosition = updatedTrackStates[trackId].position;
                        if (storedPosition > 0) {
                            // Resume from the stored position
                            audioElement.currentTime = storedPosition;
                        }

                        //play track
                        audioElement.play().catch((error) => {
                            console.log(audioElement.currentTime);
                            // Handle any play errors here
                            console.error("Error playing audio:", error);
                        });
                    } else {
                        //store current position
                        updatedTrackStates[trackId].position = audioElement.currentTime;
                        //pause track
                        audioElement.pause();
                    }
                } else {
                    console.error("Audio element ref not found for track:", trackId);
                }
            }
        }

        // Update track states
        setTrackStates(updatedTrackStates);

        // Expand track when playing and not already expanded
        if (!trackStates[trackId].expanded && trackStates[trackId].playing) {
            toggleExpandTrack(trackId);
        }
    }, [trackStates, audioRefs.current]);

    //stop track on end
    const handleTrackEnded = useCallback((trackId) => {
        const updatedTrackStates = { ...trackStates };
        updatedTrackStates[trackId].playing = false;
        setTrackStates(updatedTrackStates);
    }, [trackStates]);

    //expand tracks to reveal details
    const toggleExpandTrack = useCallback((trackId) => {
        const updatedTrackStates = { ...trackStates };
        
        for (const id in updatedTrackStates) {
            if (id != trackId) {
                //collapse expanded track if open when another track is clicked
                updatedTrackStates[id].expanded = false;
            } else {
                //toggle track expanded
                updatedTrackStates[trackId].expanded = !updatedTrackStates[trackId].expanded;
            }
        }
        setTrackStates(updatedTrackStates);
    }, [trackStates]);

    return (
        <div className='audio-player-container'>
            {AudioContent.map((track) => (
                <div key={track.id}>
                    {/*play / pause button */}
                    <div
                        className='audio-player-play-pause-container'
                        onClick={() => togglePlayPause(track)}
                    >
                        <button
                            aria-label='Toggle Audio Play'
                            className={`audio-player-play-pause-button ${trackStates[track.id].playing ?
                                'audio-player-play-pause-button-playing' :
                                'audio-player-play-pause-button-paused'}`}
                        >
                        </button>
                    </div>

                    {/*track expanded*/}
                    <div
                        key={track.id}
                        className={`audio-player-track-box ${trackStates[track.id].expanded ? 'audio-player-track-box-expanded' : ''}`}
                        onClick={() => toggleExpandTrack(track.id)}
                    >

                        {/*title / progress bar */}
                        <div className={classNames('audio-player-track-header', {
                            'audio-player-track-header-expanded': trackStates[track.id].expanded
                        })}>
                            {/*HTML audio tag fuckery*/}
                            <audio controls ref={audioRefs.current[track.id]} onEnded={() => handleTrackEnded(track.id)}/>

                            {/*track title */}
                            <div className="audio-player-track-title">{track.title}</div>
                        </div>

                        {/*track description */}
                        <div className={classNames('audio-player-track-details', {
                            'audio-player-track-details-expanded ': trackStates[track.id].expanded,
                            'audio-player-track-details-collapsed': !trackStates[track.id].expanded,
                        })}>
                            <p className='audio-player-track-artist'>Artist: {track.artist}</p>
                            <p className='audio-player-track-description'>{track.description}</p>
                        </div>
                    </div>
                </div>
            ))}
        </div>
    );
}

export default memo(AudioPlayer);