import React, { useState, useEffect } from 'react';

//components
import BoidBackground from '../components/boid-background';
import AudioNSTDisplay from '../components/audio-NST-display';
// import AmbisonicPlayer from '../components/ambisonic-player';

function SectionResearch() {
	const [smallDevice, setSmallDevice] = useState(false);

	const checkWidth = () => {
		const width = window.innerWidth;
		setSmallDevice(width > 768);
	};

	useEffect(() => {
		window.addEventListener('resize', checkWidth);

		checkWidth();

		return () => {
			window.removeEventListener('resize', checkWidth);
		};
	}, []);

	return (
		<div>
			<section id='research' className='research-section-container-grainflocks'>
				<div className='research-section-content'>
					<h1 className='research-section-title'>RESEARCH</h1>
					<div className='research-section-content-inner-grainflocks'>
						<div className='research-section-content-grainflocks-left'>

							<div className='research-section-text-block research-section-grainflocks-text'>
								<p className='research-section-subtitle'>grainFlocks : Flocking Granular Synthesis</p>
								<p className='research-text'>
									This sonic arts piece, exhibited at the London College of Music, combines boids - a flocking simulation algorithm designed by Craig Reynolds - with granular synthesis - a sound synthesis method of splitting a sound into separate, discrete, sound atoms and recombining to form complex grain clouds.
								</p>
								<p className='research-text'>
									In grainFlocks, individual boids represent individual grains. The timbral and textural qualities of the grain cloud are emergent phenomena of the simulation’s flocking behaviour. This exhibit attempts to relate to Curtis Road’s discussions on statistical grain clouds in a way which is visually explicit, and interactive.
								</p>
								<p className='research-text'>
									Using PoseNet - a convolutional neural network trained to detect gestures - you can control the grain flock with your movements. The grains will follow your right hand, while your left hand’s movement in the x and y direction will control the size of the grains and the density of the flock, respectively.
								</p>
								<p className='research-text'>
									This piece was awarded the The Phil & Yoko Leader Award for Innovation in Music Technologies.
								</p>
							</div>

						</div>
						<div className='research-section-content-grainflocks-right'>
							<div className='research-section-youtube-embed'>

								<iframe
									className='research-section-youtube-embed-size'
									src="https://www.youtube.com/embed/WeH2ZS5HHSo?si=TIJfVsklMp957ylw&amp;controls=0"
									title="YouTube video player"
									frameBorder="0"
									loading='lazy'
									allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
									allowFullScreen>
								</iframe>

							</div>
						</div>
					</div>
				</div>
				{smallDevice && <BoidBackground className='research-section-boid-background' />}
			</section>
			<section id='aNST' className='research-section-container-aNST'>
				<div className='research-section-content-aNST'>
					<div className='research-section-content-inner-aNST'>
						<div className='research-section-content-aNST-left'>
							<AudioNSTDisplay />
						</div>
						<div className='research-section-content-aNST-right'>
							<div className='research-section-text-block research-section-aNST-text'>
								<p className='research-section-subtitle-aNST'>Audio Neural Style Transfer</p>
								<p className='research-text'>
									The conclusive work for my Master’s project considered the use of Deep Learning techniques for sound design tasks. The research involved the investigation of Audio Neural Style Transfer (NST), an algorithm that can transfer texture features from one sample of audio to another. This algorithm was investigated for it’s potential use as an application or VST for sound designers.
								</p>
								<p className='research-text'>
									While the bulk of the project covered the technical considerations of implementing practical deep learning tools, and the results of experimentation with Audio NST, sound-design workflow was thoroughly investigated, yielding insight into the expectations and responsibilities of the sound designer.
								</p>
							</div>
						</div>
					</div>
				</div>
			</section>
			{/* <section id='research' className='research-section-container-ambisonics'>
				<div className='research-section-content'>
					<div className='research-section-content-inner-grainflocks'>
						<div className='research-section-content-grainflocks-left'>

							<div className='research-section-text-block research-section-grainflocks-text'>
								<p className='research-section-subtitle'>Ambisonics</p>
								<p className='research-text'>
									This sonic arts piece, exhibited at the London College of Music, combines boids - a flocking simulation algorithm designed by Craig Reynolds - with granular synthesis - a sound synthesis method of splitting a sound into separate, discrete, sound atoms and recombining to form complex grain clouds.
								</p>
								<p className='research-text'>
									In grainFlocks, individual boids represent individual grains. The timbral and textural qualities of the grain cloud are emergent phenomena of the simulation’s flocking behaviour. This exhibit attempts to relate to Curtis Road’s discussions on statistical grain clouds in a way which is visually explicit, and interactive.
								</p>
								<p className='research-text'>
									Using PoseNet - a convolutional neural network trained to detect gestures - you can control the grain flock with your movements. The grains will follow your right hand, while your left hand’s movement in the x and y direction will control the size of the grains and the density of the flock, respectively.
								</p>
								<p className='research-text'>
									This piece was awarded the The Phil & Yoko Leader Award for Innovation in Music Technologies.
								</p>
							</div>

						</div>
						<div className='research-section-content-grainflocks-right'>
							<div className='research-section-youtube-embed'>
								<AmbisonicPlayer />
							</div>
						</div>
					</div>
				</div>
			</section> */}
		</div>
	);
}

export default SectionResearch;