import React, { useState } from 'react';

const AudioNSTDisplay = () => {
    const [selectedContent, setSelectedContent] = useState('null');
    const [selectedStyle, setSelectedStyle] = useState('null');
    const [selectedOutput, setSelectedOutput] = useState(null);


    const [contentSamplePlaying, setContentSamplePlaying] = useState(false);
    const [styleSamplePlaying, setStyleSamplePlaying] = useState(false);
    const [resultSamplePlaying, setResultSamplePlaying] = useState(false);

    const [loading, setLoading] = useState(false);

    const contentSamples = [
        { name: 'Aphex Twin', src: `${process.env.PUBLIC_URL}/audio/audio-nst-examples/aphex.mp3` },
        { name: 'Dave Brubeck', src: `${process.env.PUBLIC_URL}/audio/audio-nst-examples/take5.mp3` },
        { name: 'Guitar', src: `${process.env.PUBLIC_URL}/audio/audio-nst-examples/guitar.mp3` }
    ]

    const styleSamples = [
        { name: 'Bonobo', src: `${process.env.PUBLIC_URL}/audio/audio-nst-examples/bonobo.mp3` },
        { name: 'Piano', src: `${process.env.PUBLIC_URL}/audio/audio-nst-examples/piano.mp3` },
    ]

    const outputSamples = [
        { name: 'Aphex + Bonobo', src: `${process.env.PUBLIC_URL}/audio/audio-nst-examples/aphexbonobo.mp3` },
        { name: 'Dave Brubeck + Bonobo', src: `${process.env.PUBLIC_URL}/audio/audio-nst-examples/take5bonobo.mp3` },
        { name: 'Guitar + Bonobo', src: `${process.env.PUBLIC_URL}/audio/audio-nst-examples/guitarbonobo.mp3` },
        { name: 'Aphex + Piano', src: `${process.env.PUBLIC_URL}/audio/audio-nst-examples/aphexpiano.mp3` },
        { name: 'Dave Brubeck + Piano', src: `${process.env.PUBLIC_URL}/audio/audio-nst-examples/take5piano.mp3` },
        { name: 'Guitar + Piano', src: `${process.env.PUBLIC_URL}/audio/audio-nst-examples/guitarpiano.mp3` }
    ]

    const togglePlayContent = () => {
        if (selectedContent !== 'null') {
            const contentAudio = document.getElementById('contentAudio');
            if (contentAudio) {
                if (contentSamplePlaying) {
                    contentAudio.pause();
                } else {
                    const styleAudio = document.getElementById('styleAudio');
                    if (styleSamplePlaying) {
                        styleAudio.pause();
                        setStyleSamplePlaying(false);
                    }
                    contentAudio.play();
                }
                setContentSamplePlaying(!contentSamplePlaying);
            }
        }
    }

    const togglePlayStyle = () => {
        if (selectedStyle !== 'null') {
            const styleAudio = document.getElementById('styleAudio');
            if (styleAudio) {
                if (styleSamplePlaying) {
                    styleAudio.pause();
                } else {
                    const contentAudio = document.getElementById('contentAudio');
                    if (contentSamplePlaying) {
                        contentAudio.pause();
                        setContentSamplePlaying(false);
                    }
                    styleAudio.play();
                }
                setStyleSamplePlaying(!styleSamplePlaying);
            }
        }
    }

    const setResult = () => {
        setLoading(true); // Show loading animation

        if (selectedContent !== 'null' && selectedStyle !== 'null') {
            const selectedContentName = selectedContent.split('/').pop();
            const selectedStyleName = selectedStyle.split('/').pop();
            const concatenatedName = `${selectedContentName.replace('.mp3', '')}${selectedStyleName.replace('.mp3', '')}.mp3`;

            setSelectedOutput(`${process.env.PUBLIC_URL}/audio/audio-nst-examples/${concatenatedName}`);
            console.log('Result set to:', selectedOutput);
        }

        setTimeout(() => {
            setLoading(false);
        }, 1000);
    };

    const playResult = () => {
        if (selectedContent !== 'null' && selectedStyle !== 'null' && selectedOutput) {
            const contentAudio = document.getElementById('contentAudio');
            const styleAudio = document.getElementById('styleAudio');
            const outputAudio = document.getElementById('outputAudio');

            if (contentSamplePlaying) {
                contentAudio.pause();
                setContentSamplePlaying(false);
            } else if (styleSamplePlaying) {
                styleAudio.pause();
                setStyleSamplePlaying(false);
            }

            if (resultSamplePlaying) {
                outputAudio.pause();
            } else {
                outputAudio.play();
            }
            setResultSamplePlaying(!resultSamplePlaying);
        }
    };

    const contentAudio = document.getElementById('contentAudio');
    if (contentAudio) {
        contentAudio.onended = () => {
            setContentSamplePlaying(false);
        }
    }

    const styleAudio = document.getElementById('styleAudio');
    if (styleAudio) {
        styleAudio.onended = () => {
            setStyleSamplePlaying(false);
        }
    }

    const outputAudio = document.getElementById('outputAudio');
    if (outputAudio) {
        outputAudio.onended = () => {
            setResultSamplePlaying(false);
        }
    }

    return (
        <div className="audio-nst-display-container">
            <div className="audio-nst-display-box">
                <div className="audio-nst-display-inner">
                    <p className='audio-nst-display-logo'>Texturise</p>

                    <audio id="contentAudio" src={selectedContent} />
                    <audio id="styleAudio" src={selectedStyle} />
                    <audio id="outputAudio" src={selectedOutput} />

                    <div className='audio-nst-display-selectors'>
                        <div className='audio-nst-display-select-box'>
                            <button 
                                aria-label='Toggle Audio Play'
                                onClick={() => togglePlayContent()}
                                className={`audio-nst-display-play-pause-button ${contentSamplePlaying ?
                                    'audio-nst-display-play-pause-button-playing' :
                                    'audio-nst-display-play-pause-button-paused'}`}
                            />

                            <select defaultValue='' className='audio-nst-display-select-dropdown' onChange={(e) => setSelectedContent(e.target.value)} name='Select Content Sample' aria-label='Select Content Sample'>
                                <option value="" disabled>Select Content</option>

                                {contentSamples.map((sample, index) => (
                                    <option key={index} value={sample.src}>
                                        {sample.name}
                                    </option>
                                ))}
                            </select>
                        </div>

                        <br />

                        <div className='audio-nst-display-select-box'>
                            <button
                                aria-label='Toggle Audio Play'
                                onClick={() => togglePlayStyle()}
                                className={`audio-nst-display-play-pause-button ${styleSamplePlaying ?
                                    'audio-nst-display-play-pause-button-playing' :
                                    'audio-nst-display-play-pause-button-paused'}`}
                            />

                            <select defaultValue='' className='audio-nst-display-select-dropdown' onChange={(e) => setSelectedStyle(e.target.value)} name='Select Style Sample' aria-label='Select Style Sample'>
                                <option value="" disabled>Select Style</option>

                                {styleSamples.map((sample, index) => (
                                    <option key={index} value={sample.src}>
                                        {sample.name}
                                    </option>
                                ))}
                            </select>
                        </div>
                    </div>
                    <div className='audio-nst-display-texture-button-container'>
                        <button
                            aria-label='Texturise Audio Sampple'
                            className='audio-nst-display-texture-button'
                            onClick={setResult}
                        >
                            {loading ? ( 
                                <div className='audio-nst-display-texture-loading-animation'></div>
                            ) : ('Texturise')}
                        </button>
                    </div>



                    <div className='audio-nst-display-result-button-container'>
                        <button
                            aria-label='Play Result'
                            className='audio-nst-display-result-button'
                            onClick={playResult}
                        >
                            Play Result
                        </button>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default AudioNSTDisplay;