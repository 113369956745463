import React, { useState, useEffect } from 'react';

//css
import './styles/App.css';

//components
import Header from './components/header';
import NavBar from './components/nav-bar';

//sections/sections
import SectionShowreel from './sections/section-showreel';
import SectionPortfolio from './sections/section-portfolio';
import SectionMusic from './sections/section-music';
import SectionResearch from './sections/section-research';
import SectionAbout from './sections/section-about';
import SectionContact from './sections/section-contact';

//data
import PortfolioContent from './data/portfolio-content';

//page styles
import './styles/section-showreel.css';
import './styles/section-portfolio.css';
import './styles/section-music.css';
import './styles/section-sound.css';
import './styles/section-research.css';
import './styles/section-about.css';
import './styles/section-contact.css';
import './styles/section-game.css';

//component styles
import './styles/nav-bar.css';
import './styles/audio-player.css';
import 'font-awesome/css/font-awesome.min.css';

function App() {
    const SectionGame = React.lazy(() => import('./sections/section-game'));

    //TODO: This is lazy, move to a hook
    const [smallDevice, setSmallDevice] = useState(false);

    const checkWidth = () => {
        const width = window.innerWidth;
        setSmallDevice(width > 768);
    };

    useEffect(() => {
        window.addEventListener('resize', checkWidth);

        checkWidth();

        return () => {
            window.removeEventListener('resize', checkWidth);
        };
    }, []);

    return (
        <div className="App">
            {/*Landing*/}
            <Header />
            <SectionShowreel />
            {/*sections*/}
            <NavBar />
            <SectionPortfolio PortfolioContent={PortfolioContent} />
            <SectionMusic />
            <SectionResearch />
            <SectionAbout />
            <SectionContact />
            {smallDevice &&
                <React.Suspense fallback={<div className='game-loading'>Loading...</div>}>
                    <SectionGame />
                </React.Suspense>
            }
        </div>
    );
}

export default App;
